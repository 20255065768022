import React from 'react';
import './JuanderCertGen.css';
import GenCanvas from './GenCanvas.js';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


function JuanderCertGen(){
  return(

    <Router>
      <div className="juander-certgen w-full h-full">
        <div className="w-full h-[56px] flex p-2 bg-[#2d3d68]">
          <img className="juander-logo box-content pr-2" src="images/juander-logo-white.png" />
          <div className="text-white text-[25px] font-bold">JUANDER CertGen</div>
        </div>
        <div className="w-full h-[calc(100%-56px)] overflow-x-hidden overflow-y-auto">
          <Switch>
            <Route path="/:queryParams?">
              <GenCanvas />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  )
}


export default JuanderCertGen;
